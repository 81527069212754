
export default () => {
	let videoPopupClick = Array.from(document.querySelectorAll('.js-video-popup-click'));
	let videoPopup = Array.from(document.querySelectorAll('.js-video-popup'));
	let closePopupCross = document.querySelector('.js-video-close');
	const ACTIVE_VIDEO_POPUP = 'video-popup--active';
	const VIDEO_IFRAME = document.querySelector('.js-video-popup');
	// loop through all the video clicks 

	videoPopupClick.forEach(videoPopupClickAll => {
		// get the data attribute of all video clicks 
		let clickUrl = videoPopupClickAll.getAttribute('data-url');
		// add click event onto them
		videoPopupClickAll.addEventListener('click', () => {

			//calls the function
			updateVideoUrl({
				url: clickUrl
			});
			VIDEO_IFRAME.classList.add(ACTIVE_VIDEO_POPUP);

		});
	})
	// remove popup with cross
	closePopupCross.addEventListener('click', () => {
		
		VIDEO_IFRAME.classList.remove(ACTIVE_VIDEO_POPUP);
		VIDEO_IFRAME.querySelector('iframe').src = '';
	});
	//remove popup with click of overlay
	VIDEO_IFRAME.addEventListener('click', (e) => {
		
		VIDEO_IFRAME.querySelector('iframe').src = '';
		return e.target === VIDEO_IFRAME ? VIDEO_IFRAME.classList.remove(ACTIVE_VIDEO_POPUP) : null;
	});

	let updateVideoUrl = ({
		url,
		iframe = VIDEO_IFRAME
	}) => {
		iframe.querySelector('iframe').src = `${url}` + "?rel=0&showinfo=0&autoplay=1&enablejsapi=1";
	};
}



