export function PostCodeSearch() {
  const postCodeInput = document.querySelector('.js-find-office-input');
  const findOfficeBtn = document.querySelector('.js-find-office-btn');

  function submitPostCodeSearch() {
    window.location.href = `${window.location.protocol}//${window.location.hostname}/find-your-local-office?location=${postCodeInput.value}`;
  }

  if(findOfficeBtn && postCodeInput) {
    findOfficeBtn.addEventListener('click', (e) => {
      e.preventDefault();
      submitPostCodeSearch();
    })
    postCodeInput.addEventListener('keydown', (e) => {
      if (e.keyCode == 13) {
        submitPostCodeSearch();
      }
    });
  }
}