import {PostCodeSearch
} from '../modules/postcode-search';
import VideoPopup from '../modules/video-popup';
import { fader } from '../modules/fader';
const burgerMenu = document.querySelector(".js-menu");
const mainMenu = document.querySelector(".js-main-menu");
const searchButton = document.querySelector(".js-search-button");
const searchBar = document.querySelector(".js-search-bar");
const allChildLevel = Array.from(document.querySelectorAll(".js-child-level"));
const body = document.body;

const ACTIVE_TRIGGER = 'nav__trigger--active';
const CHILD_MENU_ACTIVE = 'child-menu-active';
const ACTIVE_SEARCH = "active-search";

document.addEventListener('DOMContentLoaded', () => {
  PostCodeSearch();
  VideoPopup();
  fader();
});

searchButton.addEventListener('click', function () {
	setTimeout(function () {
		searchBar.classList.toggle(ACTIVE_SEARCH);
	}, 300);
  mainMenu.classList.remove("main-menu-active");
  burgerMenu.classList.remove("nav__menu--active");
  body.classList.remove('body-active-nav');
});

searchBar.querySelector('.js-search-input').addEventListener('click', function () {
	if (window.innerWidth > 1024) {
		searchBar.classList.add('active-search');
	}
})

//mobile only
burgerMenu.addEventListener('click', function () {
	let activeChildern = Array.from(document.querySelectorAll(CHILD_MENU_ACTIVE));

	this.classList.toggle('nav__menu--active');
  body.classList.toggle('body-active-nav');
	activeChildern.forEach(reset => {
		reset.classList.remove(CHILD_MENU_ACTIVE);
	});

	// activeChildern.classList.remove(CHILD_MENU_ACTIVE);
	searchBar.classList.remove(ACTIVE_SEARCH);

	setTimeout(function () {

		mainMenu.classList.toggle("main-menu-active");
	}, 300);
});

//shared code on mobile and desktop
let addListeners = () => {
	let triggers = Array.from(document.querySelectorAll('.js-menutrigger'));
	//loop through 
  triggers.forEach(trigger => {
    
		// Get all second level clickers (within loop)
    let nextLevelContainer = trigger.parentNode.querySelector('.js-child-level');


		// if top-level nav item has a child (meganav dropdown)
		if (nextLevelContainer !== undefined) {
			let megaNavBlock = trigger.parentNode.querySelector('.js-menu-block'); //the whole megamenu item
      

      //-------------- SECONDARY NAV CLICk -------------------
      //(once opened by hover)
      
      trigger.addEventListener('click', (clickEvent) => {
				if (window.innerWidth > 1024) {
          

          //remove 'active' styling when a sibling trigger is clicked
          let triggerSiblings = trigger.closest('ul').querySelectorAll('.js-menutrigger');
          let triggerSiblingsArray = Array.from(triggerSiblings);

          triggerSiblingsArray.forEach(triggerActive => {
            triggerActive.classList.remove(ACTIVE_TRIGGER);
          });      
          // add trigger active to current/clicked trigger for styling                   
          trigger.classList.add(ACTIVE_TRIGGER);


          let parent = trigger.closest('.js-child-level');
					if (parent !== null) {
						Array.from(parent.querySelectorAll('.js-child-level')).forEach(child => {
							child.classList.remove(CHILD_MENU_ACTIVE);
						})
					} else {
						allChildLevel.forEach(child => {
							child.classList.remove(CHILD_MENU_ACTIVE);
						})
					}
				} else {
					allChildLevel.forEach(child => {
						child.classList.remove(CHILD_MENU_ACTIVE);
					})
        }
        
        //targets first child and adds child_menu_active
        if (nextLevelContainer.closest('[data-parent]') !== null) {
          nextLevelContainer.closest('[data-parent]').classList.add(CHILD_MENU_ACTIVE);
        }
        nextLevelContainer.classList.add(CHILD_MENU_ACTIVE);
				// if on desktop menu (we know this because the main-menu-active class isn't present) 
        // automatically add active class to the first child

        //console.log(mainMenu.classList.contains("main-menu-active"));
				if (!mainMenu.classList.contains("main-menu-active")) {
          
          let childToAutoShow = nextLevelContainer.querySelector('.js-child-level');
					if (childToAutoShow) {
            childToAutoShow.classList.add(CHILD_MENU_ACTIVE);
					}
				}
      });

      

      //-------------- TOP-LEVEL NAV HOVER ---------
      //if has meganav 
      if (megaNavBlock !== null) {
        
				// open meganav dropdown on hover
        trigger.addEventListener('mouseenter', () => {
          

          // hide the other dropdowns (js-menu-block)
					if (window.innerWidth > 1024) {
						Array.from(document.querySelectorAll('.js-menu-block')).map(a => {
							a.classList.remove(CHILD_MENU_ACTIVE);
						});
            
            
            //show this dropdown
            megaNavBlock.classList.add(CHILD_MENU_ACTIVE);

            //highlight the first of the first-level items
            //unless one is already hightlighted
            let firstLevelTriggers = megaNavBlock.querySelectorAll('.nav__trigger');
            let firstLevelTriggersActive = megaNavBlock.querySelectorAll(`.${ACTIVE_TRIGGER}`);


            if (firstLevelTriggers.length && firstLevelTriggersActive.length < 1) {
              firstLevelTriggers[0].classList.add(ACTIVE_TRIGGER);
            }
            

            //get second menu level
            let secondLevel = megaNavBlock.querySelector('.js-child-level');
						if (secondLevel !== null) {
              secondLevel.classList.add(CHILD_MENU_ACTIVE);           
						}
					}
				});
				//hover out 
				megaNavBlock.addEventListener('mouseleave', removeActiveClassFromMeganavItems);
        document.querySelector('.js-nav-secondary').addEventListener('mouseenter', removeActiveClassFromMeganavItems);
			}

			//mobile only
			let backBtn = nextLevelContainer.querySelector('.js-back-trigger');
			backBtn.addEventListener('click', () => {
				nextLevelContainer.classList.remove(CHILD_MENU_ACTIVE);
			});
		}
	});
}

let removeActiveClassFromMeganavItems = () => {
  if (window.innerWidth > 1024) {
  	// console.log('leaving')
  	Array.from(document.querySelectorAll('.js-menu-block')).map(a => {
  		//js-menu-block to hide 
  		a.classList.remove(CHILD_MENU_ACTIVE);
  	});
  }
}

addListeners();

var rteLinks = Array.prototype.slice.call(document.querySelectorAll('.rich-text a'));
var viewMoreLinks = Array.prototype.slice.call(document.querySelectorAll(".nav__view-more a"));

let generateHashLinkClickers = arr => {
  arr.map(a => {
    if (a.href.startsWith(window.location.href.split('#')[0]) && a.href.includes('#')) {
      a.addEventListener('click', function (e) {
        e.preventDefault();
        if (
          document.getElementById(a.href.split("#")[1]) !== null &&
          document.getElementById(a.href.split("#")[1])
        !== undefined) {
            window.scroll({
              top:
                document.getElementById(a.href.split("#")[1])
                  .offsetTop - 200,
              left: 0,
              behavior: "smooth"
            });
        } else {
          window.location = a.href;
        }
      })
    }
  });
};

if(window.location.href.split('#').length > 1) {
  let hashedEl = document.getElementById(window.location.href.split('#')[1]);
  if(hashedEl !== undefined && hashedEl !== null) {
    window.scroll({
      top: document.getElementById(window.location.href.split('#')[1]).offsetTop - 200,
      left: 0,
      behavior: 'smooth'
    });
  }
}

generateHashLinkClickers(rteLinks);
generateHashLinkClickers(viewMoreLinks);