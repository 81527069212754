export function fader() {
  const faders = Array.from(document.getElementsByClassName('fader'));

  for (const fader of faders) {
    const images = Array.from(fader.getElementsByTagName('img'));

    const numImages = images.length;
    let currentId = 0;

    images[currentId].classList.add("active");

    setInterval(() => {
      images[currentId % numImages].classList.remove("active");
      images[++currentId % numImages].classList.add("active");
    }, 5000);
  }
}